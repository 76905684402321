import React, {useState} from 'react'
import {HashRouter as Router, Route, Routes, Navigate} from 'react-router-dom'

import BoludaPageHeader from "./pageheader/BoludaPageHeader";
import Private from "./Private";
import LoginSeite from "./auth/LoginSeite";
import LogoutSeite from "./auth/LogoutSeite";
import Benutzerliste from "./settings/benutzer/Benutzerliste";
import BenutzerdetailSeite from "./settings/benutzer/BenutzerdetailSeite";
import ToernBearbeitenSeite from "./toerns/ToernBearbeitenSeite";
import ToernRuhezeitenListe from "./toerns/ToernRuhezeitenListe";
import ToernRuhezeitenBearbeitenSeite from "./toerns/ToernRuhezeitenBearbeitenSeite";
import ToernDetailSeite from "./toerns/ToernDetailSeite";
import SettingsMenu from "./settings/SettingsMenu";
import SettingsSeite from "./settings/SettingsSeite";
import TarifUebersichtSeite from "./settings/tarife/TarifUebersichtSeite";
import Schlepperliste from "./settings/schlepper/Schlepperliste";
import TarifzeitraumBearbeitenSeite from "./settings/tarife/TarifzeitraumBearbeitenSeite";
import TarifzeitraumUebersichtSeite from "./settings/tarife/TarifzeitraumUebersichtSeite";
import BordpersonalListe from "./bordpersonal/BordpersonalListe";
import BordpersonalAbschlaegeDetailseite from "./bordpersonal/BordpersonalAbschlaegeDetailseite";
import BordpersonalSeite from "./bordpersonal/BordpersonalSeite";
import MeineToernsListe from "./toerns/MeineToernsListe";
import ToernPruefenListe from "./toerns/ToernPruefenListe";
import ToernSeitenMenu from "./toerns/ToernSeitenMenu";
import authenticationService from "../services/AuthenticationService";
import AktiveToerns from "./toerns/AktiveToerns";
import AlleToernsListe from "./toerns/AlleToernsListe";
import PasswortVergessenSeite from "./auth/PasswortVergessenSeite";
import {App} from "antd";
import PasswortTokenSeite from "./auth/PasswortTokenSeite";
import ToernMonatsberichteListe from "./toerns/berichte/ToernMonatsberichteListe";
import ToernGenehmigungberichtListe from "./toerns/berichte/ToernGenehmigungberichtListe";

const BoludaApp = () => {
  const [loginState, setLoginState] = useState(false);

  return (
    <App>
      {/*
      <Router basename={"/_"}>
      */}
      <Router>
        <BoludaPageHeader loginState={loginState}/>

        <Routes>
          <Route path="/" element={
            <Private>
              <Navigate replace to="/toerns" />
            </Private>
          }/>
          <Route path="/login" element={
            <LoginSeite onLogin={setLoginState}/>
          }/>
          <Route path="/logout" element={
            <LogoutSeite onLogout={setLoginState}/>
          }/>

          <Route path="/passwort-vergessen" element={
            <PasswortVergessenSeite />
          }/>

          <Route path="/passwort-token/:token?" element={
            <PasswortTokenSeite />
          }/>

          <Route path="/toerns" element={<Private><ToernSeitenMenu/></Private>}>
            {<Route index element={
              authenticationService.isBordPersonal() ?
                <Navigate replace to="/toerns/aktive" /> :
                <Navigate replace to="/toerns/eingereicht" />
            }/>}
            <Route path='aktive' element={<AktiveToerns/>}/>
            <Route path='meine/:jahr?/:monat?' element={<MeineToernsListe/>}/>
            <Route path='eingereicht/:jahr?/:monat?' element={<ToernPruefenListe/>}/>
            <Route path='alle/:jahr?/:monat?' element={<AlleToernsListe/>}/>
            <Route path='berichte/monat/:jahr?/:monat?' element={<ToernMonatsberichteListe/>} />
            <Route path='berichte/genehmigung/:von?/:bis?' element={<ToernGenehmigungberichtListe/>}/>
            {/*<Route path='monat/:jahr?/:monat?' element={<ToernMonatsberichteListe/>}/>*/}
          </Route>

          <Route path="/bordpersonal" element={<Private><BordpersonalSeite/></Private>}>
            <Route index element={<BordpersonalListe/>}/>
            <Route path='details/:id' element={<BordpersonalAbschlaegeDetailseite/>}/>
          </Route>

          <Route path="/toern/:toernId" element={<Private><ToernDetailSeite/></Private>}>
            <Route index element={<ToernBearbeitenSeite/>}/>
            <Route path='ruhezeiten' element={<ToernRuhezeitenListe/>}/>
            <Route path='ruhezeit/:ruhezeitId' element={<ToernRuhezeitenBearbeitenSeite/>}/>
          </Route>

          <Route path="/settings" element={<Private><SettingsSeite/></Private>}>
            <Route index element={<Private><SettingsMenu/></Private>}/>
            <Route path='tarife' element={<TarifUebersichtSeite/>}>
              <Route index element={<TarifzeitraumUebersichtSeite/>}/>
              <Route path='tarifzeitraum/:id' element={<TarifzeitraumBearbeitenSeite/>}/>
              <Route path='tarifzeitraum/:id' element={<TarifzeitraumBearbeitenSeite/>}/>
            </Route>
            <Route path='benutzer' element={<Private><Benutzerliste/></Private>}/>
            <Route path='benutzer/details/:id?/:tab?' element={<Private><BenutzerdetailSeite/></Private>}/>
            <Route path='schlepper' element={<Private><Schlepperliste/></Private>}/>
          </Route>
        </Routes>
      </Router>
    </App>
  )
}

export default BoludaApp
