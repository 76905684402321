import React, {useEffect, useState} from 'react'
import {
  Button, Col,
  DatePicker,
  Form,
  InputNumber,
  Layout,
  Tooltip,
  message, Row,
  Space, Table, Input, Switch,
} from 'antd';
import {Link, useOutletContext, useParams} from "react-router-dom";
import { useImmer } from 'use-immer';

import benutzerService from '../../services/BenutzerService'
import {ArrowLeftOutlined, SaveOutlined, UndoOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import LadeIndikator from "../LadeIndikator";
import {AbschlagIcon} from "../icons/CustomIcons";

const {Content} = Layout;

//const currencyFormatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })
const currencyFormatter = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const BordpersonalAbschlaegeDetailseite = () => {
  let {id} = useParams();
  const [jahr, setJahr] = useOutletContext();

  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(id !== 'neu');
  const [benutzer, setBenutzer] = useState({});
  const [abschlaege, updateAbschlaege] = useImmer([]);


  const [tableData, setTableData] = useState([]);
   useEffect(() => {
     setTableData(
       [...Array(12).keys()].map(m => {
         return {
           idx: m,
           key: "abschlag-" + m,
           monat: dayjs().utc().year(jahr).month(m).startOf('month'),
           disabled: (abschlaege && abschlaege[m]) ? false : true,
           abschlag: (abschlaege && abschlaege[m]) ? abschlaege[m].abschlag : 0,
           tarif: (abschlaege && abschlaege[m]) ? abschlaege[m].tarifAbschlag : 0,
           manuell: (abschlaege && abschlaege[m]) ? abschlaege[m].manuell : false,
         }
       })
     );
  }, [abschlaege]);


  const error = (msg) => {
    messageApi.open({
      type: 'error', content: msg,
    });
  }

  useEffect(() => {
    ladeBenutzer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id !== 'neu') {
      getBenutzerabschlaege();
    }
  }, [ jahr, benutzer ]);

  const ladeBenutzer = async () => {
    setLoading(true);
    await benutzerService.getBenutzer(id).then(
      res => {
        setLoading(false);
        setBenutzer(res.data)
      }
    ).catch((e) => {
      setLoading(false);
      setBenutzer({})
      // TODO: Fehler einblenden?
      console.log("Fehler: ", e);
      switch (e.response.status) {
        case 404:
          error(`Der Benutzer wurde nicht gefunden`);
          // TODO: Disable Form
          break;
        default:
          error(`Beim Laden der Benutzerdaten ist ein unbekannterFehler aufgetreten`);
      }
    });
  };

  const getBenutzerabschlaege = async () => {
    setLoading(true);
    await benutzerService.retrieveAbschlaege(id, jahr).then(
      res => {
        setLoading(false);
        updateAbschlaege(res.data);
      }
    ).catch((e) => {
      error(e.response.data.message ? e.response.data.message :  "Es ist ein unbekannter Fehler aufgetreten." );
      console.log("Error ", e);
      updateAbschlaege([]);
      setLoading(false);
    });
  };

  const updateAbschlag = (wert, idx) => {
    updateAbschlaege(draft => {
      draft[idx].abschlag = wert;
      if (wert) {
        draft[idx].manuell = true
      } else {
        draft[idx].manuell = false
      }
    });
  }

  const updateManuell = (wert, idx) => {
    console.log("W: " + wert + " I: " + idx);
    updateAbschlaege(draft => {
      draft[idx].manuell = wert;
    });
  }

  const speichereAbschlaege = () => {
    var postData = {};
    postData["jahr"] = jahr;
    postData["abschlaege"] = abschlaege;

    /*
    var postData = formData["monate"].map((m,i) => {
      return {
        // TODO: Jahr!
        "monat": m,
        "abschlag": formData["abschlaege"][i],
        "manuell": formData["manuell"][i]
      }
    });
    */
    benutzerService.saveAbschlaege(benutzer.id, postData).then(
      () => {
        messageApi.open({
          type: 'info', content: "Die SFN-Zuschläge wurden gespeichert",
        });
      }
    ).catch((e) => {
      error(e.response.data.message ? e.response.data.message :  "Es ist ein unbekannter Fehler aufgetreten." );
      console.log("Error ", e);
    });
  }

  const columns=[
    {
      title: 'Monat',
      dataIndex: 'monat',
      key: 'monat',
      render: (monat) => monat.format("MMMM")
    },
    {
      title: 'SFN-Zuschlag',
      dataIndex: 'abschlag',
      key: 'abschlag',
      render: (abschlag, record) =>
        <InputNumber
          value={abschlag}
          onChange={w => updateAbschlag(w, record.idx)}
          disabled={record.disabled}
          step={0.01}
          placeholder={record.tarif}
          parser={(value) => value?.replace(',', '.').replace(/[.](?=.*[.])/g, "")}
          addonAfter={"€"}
        />
    },
    {
      title: 'Tarif',
      dataIndex: 'tarif',
      key: 'tarif',
      render: (t) => t ? currencyFormatter.format(t) + " €" : "-"
    },
    {
      title: 'Angepasst',
      dataIndex: 'manuell',
      key: 'manuell',
      // TODO: Form manuell?
      render: (id, record) => <>
        <Switch checkedChildren="angepasst" unCheckedChildren="Tarif"
                checked={record.manuell}
                onChange={m => updateManuell(m, record.idx)}
                disabled={record.disabled}
        />
      </>
    }
  ]

  return (
    <>
      {contextHolder}
      <Layout>
        <Layout.Header style={{ backgroundColor: "#cccccc", display: 'flex'}} >
          <Space>
            <Link to={"/bordpersonal"}><Button icon={<ArrowLeftOutlined/>} >Zur Übersicht</Button></Link>
            <DatePicker defaultValue={dayjs().utc().year(jahr)} format={'YYYY'} picker="year"
                        onChange={ (ev) => setJahr(ev ? dayjs(ev.$d).year() : dayjs().utc().year() ) } />
            <Button icon={<SaveOutlined  />}
     //       onClick={() => form.submit()}
            >
              Speichern
            </Button>
          </Space>
        </Layout.Header>
        <Content>
          {loading ? (
            <LadeIndikator />
          ) : (
            <>
                    <Table
                      title={() => (
                      <Row gutter={[0,0]}>
                        <Col flex="auto"><h2 style={{margin:'0px'}}><AbschlagIcon/> SFN-Zuschläge für {benutzer.vorname} {benutzer.nachname}</h2></Col>
                      </Row>
                      )}
                      pagination={false}
                      columns={columns}
                      dataSource={tableData}
                    //  dataSource={abschlagliste}
                    ></Table>

                <div>
                  <br/>
                  <Button icon={<SaveOutlined  />} onClick={speichereAbschlaege}>
                    Speichern
                  </Button>
                  <br/>&nbsp;
                </div>
            </>
          )}
        </Content>
      </Layout>
    </>



  )
}
export default BordpersonalAbschlaegeDetailseite
