import React, {useEffect, useState} from 'react'
import { useOutletContext } from "react-router-dom";
import {
  AutoComplete,
  Avatar,
  Button, Col,
  DatePicker,
  Form, Input,
  InputNumber,
  Layout,
  message, Popconfirm, Row,
  Select,
  Space, Tag, Typography,
} from 'antd';
import {useNavigate} from 'react-router-dom';
import toernService from "../../services/ToernService";
import schlepperService from "../../services/SchlepperService";
import authenticationService from "../../services/AuthenticationService";
import dayjs from "dayjs";
import {
  FilePdfOutlined,
  MessageOutlined,
  RollbackOutlined, SaveOutlined, SendOutlined
} from '@ant-design/icons';
import {Comment} from '@ant-design/compatible';
import LadeIndikator from "../LadeIndikator";
import './ToernBearbeitenSeite.css';

import { CookiesProvider, useCookies } from 'react-cookie'

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {red} from "@ant-design/colors";
import {DlIcon} from "../icons/CustomIcons";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Berlin');

const {Content} = Layout;

dayjs.locale('de')

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 0,
      span: 24
    },
    sm: {
      span: 16,
      offset: 8
    },
    md: {
      span: 16,
      offset: 8
    },
    lg: {
      span: 16,
      offset: 8
    }
  }
};

const ToernBearbeitenSeite = () => {
  const [, setCookie] = useCookies(['tug'])
  const [toern, setToern, loading] = useOutletContext();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [kommentarForm] = Form.useForm();
  const [schlepper, setSchlepper] = useState([]);

  const [einreichbar, setEinreichbar] = useState(false);


  useEffect(() => {
    getSchlepperFromServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldValue('toernId', toern.id)
    form.setFieldValue('startzeit', dayjs(toern.startzeit))
    form.setFieldValue('endezeit', toern.endezeit ? dayjs(toern.endezeit) : null)
    form.setFieldValue('schlepper', toern.schlepper)
    form.setFieldValue('rang', toern.rang)
    form.setFieldValue('hafentage', toern.hafentage)
    form.setFieldValue('reisekilometer', toern.reisekilometer)
    form.setFieldValue('shuttlekilometer', toern.shuttlekilometer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toern]);

  const getSchlepperFromServer = async () => {
    await schlepperService.getSchlepper().then(
      res => {
        setSchlepper(res.data.map(row => ({value: row.name})));
      }
    ).catch((e) => {
      console.log("Fehler beim Holen der Schlepper: ", e);
    });
  };

  const showInfo = (msg) => {
    messageApi.open({
      type: 'info', content: msg,
    });
  }
  const rundeZeitAufMinuten = (date, minuten) => {
    if (!date) return null;
    const roundedMinutes = Math.round(date.minute() / minuten) * minuten;
    return date.clone().minute(roundedMinutes).second(0);
  };

  const validiereZeit15 = (rule, value) => {
    if (!value) {
      return Promise.resolve();
    }
    const zeitGerundet = rundeZeitAufMinuten(value, 15)
    if (!zeitGerundet.isSame(value, 'minutes')) {
      form.setFieldValue(rule.field, zeitGerundet)
      return Promise.reject(new Error(`Die Zeit wurde auf 15 Minuten gerundet: ${zeitGerundet.format('HH:mm')}`));
    }
    return Promise.resolve();
  };

  const validiereEndezeit = (rule, value) => {
    if (!value) {
      return Promise.resolve();
    }

    const start = form.getFieldValue('startzeit');
    if (start !== null && !value.isAfter(start)) {
      return Promise.reject(new Error(`Die Endezeit muss nach der Startzeit liegen`));
    }
    return Promise.resolve();
  }

  const validiereEndezeitEinreichbar = (rule, value) => {
    if (!value) {
      return Promise.reject(new Error(`Zum Einreichen muss die Endezeit angebeben werden`));
    }

    if (dayjs(value).isAfter(dayjs())) {
      return Promise.reject(new Error(`Zum Einreichen darf die Endezeit nicht in der Zukunft liegen`));
    }

    return Promise.resolve();
  }

  // Nach dem Laden validieren
  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form
      .validateFields()
      .then(() => {
        const errors = form.getFieldsError();
        const warnings = errors.filter(
          (field) => field.errors.length === 0 && field.warnings?.length > 0
        );
        setEinreichbar(( toern.status !== 'EINGEREICHT') && warnings.length === 0 )
      })
      .catch(() => setEinreichbar(false));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, values]);

  const addKommentar = async (frm) => {
    var kmt = {
      text: frm.kommentar,
    }
    if (frm.kommentar.trim().length === 0 ) {
      error("Kein Inhalt im Kommentar!")
    } else {
      await toernService.addKommentar(toern.id, kmt).then(
        res => {
          setToern(res.data)
          kommentarForm.setFieldValue('kommentar', '');
        }
      ).catch((e) => {
        error("Fehler beim Speichern des Kommentars: ", e);
      });

    }
  }

  const error = (msg) => {
     messageApi.open({
      type: 'error', content: msg,
    });
  }



  const replaceComma = (value) => {
    return value && (typeof value === 'string' || value instanceof String) ? value.replace(',', '.') : value;
  }

  const einreichen = (id) => {
    toernService.toernEinreichen(id ? id : toern.id)
    navigate(-1);
  };

  const toernEditierbar = () => {
    return toern.status !== "EINGEREICHT" && toern.status !== "GENEHMIGT";
  }

  const hafentageEditierbar = () => {
    switch (toern.status) {
      case "EINGEREICHT" :
        return authenticationService.isHeuer();
      case "GENEHMIGT" :
        return false;
      default :
        return true;
    }
  }

  const onFinish = (frm) => {
    if (frm.schlepper) {
      setCookie("tug", frm.schlepper, {path: '/', expires: new Date(Date.now() + 2592000)})
    }

    var t = {
      id: frm.toernId,
      startzeit: dayjs(frm.startzeit),
      endezeit: frm.endezeit ?  dayjs(frm.endezeit) : null,
      rang: frm.rang,
      schlepper: frm.schlepper,
      hafentage: frm.hafentage,
      reisekilometer: replaceComma(frm.reisekilometer),
      shuttlekilometer: replaceComma(frm.shuttlekilometer),
      kommentar: frm.kommentar
    }

    let promiseChain;

    if (t.id) {
      if ((toern.status === 'EINGEREICHT') && (t.hafentage !== toern.hafentage)) {
        var kmt = {
          kommentar: "Hafentage von " + toern.hafentage + " auf " + t.hafentage + " geändert.",
        }
        promiseChain = addKommentar(kmt).then(() => toernService.saveToern(authenticationService.getLoggedInUserName(), t));
      } else {
        promiseChain = toernService.saveToern(authenticationService.getLoggedInUserName(), t);
      }
    } else {
      promiseChain = toernService.createToern(authenticationService.getLoggedInUserName(), t);
    }

    return promiseChain
      .then(res => {
        showInfo("Die Daten wurden gespeichert");
        setToern(res.data)
        navigate(`/toern/${res.data.id}`, { replace: true });
        setToern(res.data)
        return res.data;
      })
      .catch((errorx) => {
        // Error
        if (errorx.response) {
          var errs = errorx.response.data;
          for (var k of Object.keys(errs)) {
            form.setFields([{name: k, errors: [errs[k]]}]);
          }
          error("Bitte prüfen Sie die Eingaben");
        } else {
          error("Es ist ein unbekannter Fehler aufgetreten. Bitte prüfen Sie die Eingaben und versuchen Sie es erneut");
        }
      }
    );
  };

  //TODO: form.isFieldsTouched

  return (
    <React.StrictMode>
      <CookiesProvider>
      {contextHolder}
      <Layout>
        <Layout.Header style={{backgroundColor: "#cccccc", display: 'flex', alignItems: 'center'}}>
          <Space>
            <Button onClick={() => navigate(-1)} icon={<RollbackOutlined/>}><span className="buttonLabel">Zurück</span></Button>
            {/*
            <Link to={`/toern/${toern.id}/ruhezeiten`}><Button icon={<HourglassOutlined/>} >Ruhezeiten</Button></Link>
            */}
            <Button icon={<SendOutlined />} htmlType="button" onClick={einreichen} disabled={!einreichbar} >Einreichen</Button>

            <Button icon={<FilePdfOutlined/>} href={`/pdf/toern/open/toern_${toern.id}.pdf`} target={"_blank"}><span className="buttonLabel">PDF</span></Button>
            <Button icon={<DlIcon />} href={`/pdf/toern/download/toern_${toern.id}.pdf`} target={"_blank"}>
            </Button>
          </Space>
        </Layout.Header>
        <Content>
          {loading ? (
            <LadeIndikator/>
          ) : (
            <>
            <Form
              {...layout}
              form={form}
              onFinish={onFinish}
              style={{
                maxWidth: 600,
              }}
            >
              <h2>Törn {toern.id ? "bearbeiten" : "anlegen" }</h2>

              <Form.Item name={'toernId'}
                         hidden={true}
              ><Input /></Form.Item>

              <Form.Item>
                <Row>
                <Col span={8}></Col>
                <Col span={16}>
                {toernService.toernStatus(toern.id ? toern.status : "NEU")}
                </Col>
                </Row>
              </Form.Item>

              <Form.Item label="Start" name={'startzeit'}
                   rules={[{
                     required: true, message: 'Die Startzeit muss angegeben werden!',
                   },{
                     validator: validiereZeit15,
                     warningOnly: true,
                   },
                   ]}
              >
                <DatePicker style={{width: '100%'}}  format={"DD.MM.YYYY HH:mm"} minuteStep={15}
                            disabled={!toernEditierbar()}
                            showTime={{
                              defaultValue: (dayjs().minute(0)),
                              needConfirm: false,
                            }}
                />
              </Form.Item>

              <Form.Item label="Ende" name={'endezeit'}
                   rules={[
                       {
                         validator: validiereZeit15,
                         warningOnly: true,
                       },
                       {
                         validator: validiereEndezeit,
                       //  message: 'Das Törnende muss nach dem Törnstart sein',
                       },
                       {
                         validator: validiereEndezeitEinreichbar,
                         warningOnly: true,
                       }
                   ]}
              >
                <DatePicker style={{width: '100%'}}  format={"DD.MM.YYYY HH:mm"} minuteStep={15}
                            disabled={!toernEditierbar()}
                            showTime={{
                              defaultValue: [dayjs().minute(Math.floor(dayjs().minute() / 30) * 30), dayjs().minute(Math.ceil(dayjs().minute() / 30) * 30)],
                              needConfirm: false,
                            }}
                />
              </Form.Item>

              <Form.Item label="Schlepper" name={'schlepper'}>
                <AutoComplete options={schlepper}
                              disabled={!toernEditierbar()}
                              filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                              }
                              allowClear={true}
                />
              </Form.Item>
              <Form.Item label="Hafentage" name={'hafentage'}>
                <InputNumber
                  disabled={!hafentageEditierbar()}
                  step={.25}
                  precision={2}
                  parser={(value) => value?.replace(',', '.').replace(/[.](?=.*[.])/g, "")}
                  style={{width: '100%'}}/>
              </Form.Item>
              <Form.Item label="Reisekosten (km)" name={'reisekilometer'}>
                <InputNumber disabled={!toernEditierbar()} precision={0} suffix={<Tag>30ct/km</Tag>} style={{width: '100%'}}/>

              </Form.Item>
              <Form.Item label="Kollegen-Shuttle (km)">
                <Space style={{width: '100%'}}>
                  <Form.Item noStyle name={'shuttlekilometer'}>
                    <InputNumber disabled={!toernEditierbar()} precision={0} suffix={<Tag>80ct/km</Tag>} style={{width: '150px'}}/>
                  </Form.Item>
                  <Typography type={"secondary"}><small>Kollegen in Kommentar angeben</small></Typography>
                </Space>
              </Form.Item>
              <Form.Item label="Rang" name={'rang'} initialValue=""
                   rules={[{
                     required: true, message: 'Der Rang muss ausgewählt werden!',
                   }]}
              >
                <Select
                  disabled={!toernEditierbar()}
                  style={{width: '100%'}}
                  options={[
                    {value: 'Kapitaen', label: 'Kapitän'},
                    {value: 'Maschinist', label: 'Maschinist'},
                    {value: 'Steuermann', label: 'Steuermann'},
                    {value: 'Schiffsmechaniker', label: 'Schiffsmechaniker'},
                  ]}
                />
              </Form.Item>

              { toern.id ? "" :
                <Form.Item
                  label={"Kommentar:"}
                  name={'kommentar'}
                  style={{"textAlign":"left"}}
                >
                  <Input.TextArea rows={4}/>
                 </Form.Item>
              }


              <Form.Item {...tailLayout}>
                <Space>
                  <Button icon={<RollbackOutlined />} onClick={() => navigate(-1)} >Zurück</Button>
                  <Button icon={form.isFieldsTouched() ? <SaveOutlined style={{ color: red[4] }} /> : <SaveOutlined  />} htmlType="submit" >{/* disabled={disabledSave} disabled={disabledSaveAndSubmit} */}
                    Speichern
                  </Button>
                  { form.isFieldsTouched() ?
                    <Popconfirm title="Speichern" description={<>Sollen ungespeicherte Änderungen gespeichert werden?</>}
                                onConfirm={() => {
                                  onFinish(form.getFieldsValue(true))
                                    .then(
                                      data => einreichen(data.id)
                                    )
                                }
                    } okText="Ja" cancelText="Nein">
                                  <Button icon={<SendOutlined />} type="primary" htmlType="button"
                                  disabled={!einreichbar} >Einreichen</Button>
                    </Popconfirm> :
                    <Button icon={<SendOutlined />} type="primary" htmlType="button" onClick={einreichen} disabled={!einreichbar} >
                      Einreichen
                    </Button>
                  }
                </Space>
              </Form.Item>
            </Form>

            { toern.id ?

            <Form
              {...layout}
              onFinish={addKommentar}
              form={kommentarForm}
              style={{
                maxWidth: 600,
              }}>

              <h2>Törn-Log</h2>

              <Form.Item
                label={"Kommentare:"}
                name={'kommentar'}
                style={{"textAlign":"left", marginBottom: 0}}
              >
                <Input.TextArea rows={4}/>
              </Form.Item>

              <Form.Item className={"emptyLabel"}
                label={" "}
                style={{"textAlign":"left"}}
              >
                <Button icon={<MessageOutlined />} htmlType="submit" >
                  Kommentieren
                </Button>
              </Form.Item>

              <Form.Item
                label={"Historie"}
                style={{"textAlign":"left"}}
              >

                <Space direction="vertical" style={{"width":"100%"}}>

                {toern && toern.kommentare && toern.kommentare && toern.kommentare.map(k =>
                  <Comment
                    key={k.id}
                  author={(k.benutzer && k.benutzer.vorname + " " + k.benutzer.nachname) || "Unbekannt"}
                  avatar={<Avatar className={ k.benutzer.bordpersonal ? 'avatarBord' : 'avatarNonBord' } size="middle" gap={1}>
                    { k.benutzer && k.benutzer.initialen }
                  </Avatar>}
                  content={<>{k.tags ? k.tags.map((t,idx) => <Tag key={"" + k.id + "+" + t.id } color={t==="Rückfrage"?"red":(t==="Genehmigt"?"red":"blue")}>{t}</Tag>) : null}{k.text}</>}
                  datetime={dayjs(new Date(k.zeit)).format('DD.MM.YYYY HH:mm')}
                  />)
                }
                </Space>

              </Form.Item>

            </Form>
              : "" }
            </>

          )}


        </Content>
      </Layout>
      </CookiesProvider>
    </React.StrictMode>
  )
}
export default ToernBearbeitenSeite

