import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import authenticationService from '../../services/AuthenticationService'
import {PageHeader} from "@ant-design/pro-layout";
import {Avatar, Dropdown, Menu} from "antd";
import {ClockCircleOutlined, SettingOutlined, UserOutlined} from "@ant-design/icons";
import './BoludaPageHeader.css';
import {SeamanIcon} from "../icons/CustomIcons";

const BoludaPageHeader = ({loginState}) => {
  const [menuItems, setMenuItems] = useState([]);

  const isUserLoggedIn = authenticationService.isUserLoggedIn()

  const benutzerItemsLoggedOut = [
    {
      key: '1',
      label: (<Link to={"/login"}>anmelden</Link>),
    },
  ];

  const benutzerItemsLoggedIn = [
    {
      key: '1',
      label: (<Link to={"/mein_konto"}>Mein Konto</Link>),
    },
    {
      key: '2',
      label: (
        <Link to="/logout">abmelden</Link>
      ),
    }
  ];

  const [avatarMenu, setAvatarMenu] = useState(isUserLoggedIn ? benutzerItemsLoggedIn : benutzerItemsLoggedOut);

  const avatar = () => {
    if (isUserLoggedIn) {
      var farbe = authenticationService.isBordPersonal() ? '#e33c40' : '#162847'
      var initialen = authenticationService.getInitialen();
      if (initialen) {
        return <p><Avatar style={{backgroundColor: farbe}} >{initialen}</Avatar></p>
      } else {
        return <p><Avatar style={{backgroundColor: farbe}} icon={<UserOutlined/>}/></p>
      }
    } else {
      return <Avatar style={{backgroundColor: '#cccccc'}} icon={<UserOutlined/>}/>
    }
  }

  const [menuMode, setMenuMode] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkWindowSize = () => {
    if (window.innerWidth > 599) {
      setMenuMode("horizontal");
    } else {
      setMenuMode("inline");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", checkWindowSize);
    checkWindowSize();
    return () => window.removeEventListener("resize", checkWindowSize);
  }, [checkWindowSize]);

  useEffect(() => {
    setMenuItems([
      {
        label: (
          <Link to="/">Törns</Link>
        ),
        key: 'stunden',
        icon: <ClockCircleOutlined/>,

      },
      {
        label: (
          (authenticationService.hatEineRolle(['heuer', 'admin'])) ?
            <Link to="/bordpersonal">Bordpersonal</Link>
            :
            <span>Bordpersonal</span>
        ),
        disabled: !authenticationService.hatEineRolle(['heuer', 'admin']),
        className: authenticationService.hatEineRolle(['heuer', 'admin']) ? "" : "hidden-menu-item",
        key: 'benutzer',
        icon: <SeamanIcon/>,
      },
      {
        key: 'settings',
        label: (
          (authenticationService.hatEineRolle(['heuer', 'admin'])) ?
            <Link to="/settings">Einstellungen</Link>
            :
            <span>Einstellungen</span>
        ),
        disabled: !authenticationService.hatEineRolle(['heuer', 'admin']),
        className: authenticationService.hatEineRolle(['heuer', 'admin']) ? "" : "hidden-menu-item",
        icon: <SettingOutlined/>,
      }
    ]);
    setAvatarMenu(loginState ? benutzerItemsLoggedIn : benutzerItemsLoggedOut);
  }, [loginState]);

  return (
    <>
      <PageHeader
        avatar={{src: "/img/logo-boluda.png", alt: "B"}}
        title="Boluda"
        subTitle=""
        extra={<>
          <div>
            <Menu className={'bsMenu'} style={{minWidth: "340px"}} selectedKeys={['mail']} mode={menuMode} items={menuItems} />
          </div>
          <Dropdown menu={{items: avatarMenu}}>{avatar()}</Dropdown></>
        }
      >
      </PageHeader>
    </>
  )
}
export default BoludaPageHeader