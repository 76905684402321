import React, {useEffect, useState} from 'react'
import {Button, Col, Layout, Row, message, Tabs} from 'antd';
import {Link, useOutletContext} from "react-router-dom";
import {EuroCircleOutlined} from "@ant-design/icons";
import tarifService from "../../../services/TarifService";
import Tarifzeitraumtabelle from "./Tarifzeitraumtabelle";
import {CalendarAddIcon} from "../../icons/CustomIcons";

const { Content } = Layout;

const TarifUebersichtSeite = () => {
  const [tarifgruppeId, tarifgruppeName, tarifzeitraeume, refresh] = useOutletContext();
  const [tarifgruppen, setTarifgruppen] = useState([]);
  const [loading, setloading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  const error = (msg) => {
    messageApi.open(    {
      type: 'error', content: msg,
    });
  }

  useEffect(() => {
    loadTarifgruppen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const loadTarifgruppen = async () => {
    setloading(true);
    await tarifService.getTarifgruppen().then(
      res => {
        setloading(false);
        setTarifgruppen(res.data.map(
          (row, idx) => {
            refresh();
            return {
              key: "grp_" + idx,
              label: row.gruppenname,
              children: <Tarifzeitraumtabelle zeitraeume={row.tarifzeitraeume} onDelete={deleteTarifzeitraum} />
            }
          }
        ));
      }
    ).catch((e) => {
      error(`Beim Laden der Tarife ist ein Fehler aufgetreten:`);
      console.error(e);
    });
  };

  const deleteTarifzeitraum = async (id) => {
    await tarifService.deleteTarifzeitraum(id).then(
      () => {
        loadTarifgruppen();
        confirmTarifzeitraumDeleted();
      }
    ).catch((e) => {
      error(`Beim Löschen des Tarifzeitraum ist ein Fehler aufgetreten`);
      console.error(e);
    });
  }

  const confirmTarifzeitraumDeleted = async () => {
    messageApi.success('Der Tarifzeitraum wurde gelöscht');
  }


  return (
    <Layout>
      <Layout.Header style={{ backgroundColor: "#cccccc", display: 'flex'}} >
        <Link to={"/settings/tarife/tarifzeitraum/neu"}><Button icon={<CalendarAddIcon/>} >Tarifzeitraum anlegen</Button></Link>
      </Layout.Header>

      <Content>
        <h1>Tarifzeiträume {tarifgruppeName}</h1>
        {contextHolder}
        {loading ? (
          "Loading"
        ) : (
          <Tarifzeitraumtabelle zeitraeume={tarifzeitraeume} onDelete={deleteTarifzeitraum} />
        )}
      </Content>
    </Layout>
  )
}

export default TarifUebersichtSeite