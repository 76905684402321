import React, {useEffect, useState} from 'react'
import {Button, Layout, Table, message, Space, Popconfirm, Tag, Tooltip} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import benutzerService from "../../../services/BenutzerService";
import {
  EditOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UserOutlined
} from "@ant-design/icons";
import _ from "lodash";
import LadeIndikator from "../../LadeIndikator";
import authenticationService from "../../../services/AuthenticationService";
import {SeamanIcon} from "../../icons/CustomIcons";
import dayjs from "dayjs";
import TableTitle from "../../base/TableTitle";

const { Content } = Layout;

// TODO: Kennzeichnung aktive, zukünftige Mitarbeiter

const Benutzerliste = () => {
  const [benutzerliste, setBenutzerliste] = useState([]);
  const [loading, setloading] = useState(true);
  const navigate = useNavigate();

  const [feldFilter, setFeldFilter] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");

  const handleTableChange = (pagination, filters) => {
    setFeldFilter(filters);
  };

  const handleError = (e, msg) => {
    if (e.response && e.response.data && e.response.data.status === 401) {
      message.error(`Ihre Nutzersitzung ist abgelaufen`);
      authenticationService.doLogout();
      navigate('/login');
    } else {
      message.error(msg ? msg :`Beim Laden der Daten ist ein Fehler aufgetreten`);
    }
  }
  const callApi = (apiCall, callback) => {
    apiCall().then(callback).catch((e) => {
      if (e.response && e.response.data) {
        handleError(e, e.response.data.message);
      } else if (e.request) {
        handleError(e,"Der Server antwortet im Moment nicht. Bitte versuchen Sie es in Kürze erneut")
      } else {
        handleError(e, "Es ist ein unerwartetes Problem beim Holen der Daten aufgetreten!");
      }
    });
  }

  useEffect(() => {
    getBenutzerliste();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBenutzerliste = async () => {
    setloading(true);

    await callApi(benutzerService.getBenutzerliste, (result => {
      setloading(false);
      setBenutzerliste(
        result.data.map(row => ({
          nachname: row.nachname,
          vorname: row.vorname,
          email: row.email,
          rang: row.rang,
          rollen: row.rollen,
          eintrittsDatum: row.eintrittsDatum,
          austrittsDatum: row.austrittsDatum,
          id: row.id,
          key: row.id
        }))
      )})
    )
  };

  const confirmUserDeleted = async () => {
    message.success('Der Benutzer wurde gelöscht');
  }
  const deleteBenutzer = async (id) => {
    await benutzerService.deleteBenutzer(id).then(
      () => {
        getBenutzerliste();
        confirmUserDeleted();
      }
    ).catch((e) => {
      handleError(e,`Beim Löschen des Benutzers ist ein Fehler aufgetreten`);
    });
  }

  const filterData = benutzerliste => formatter => benutzerliste.map( item => ({
    text: formatter(item),
    value: formatter(item)
  }));

  const columns=[
    {
      title: ' ',
      dataIndex: 'rollen',
      key: 'icon',
      render: (rs, record) => rs.indexOf("bordpersonal") > -1 && record.rang ? <Tooltip placement="right" title="Bordpersonal"><SeamanIcon style={{"color":"#ff0000"}} /></Tooltip> : <Tooltip placement="right" title="Kein Bordpersonal"><UserOutlined  /></Tooltip>
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Vorname',
      dataIndex: 'vorname',
      key: 'vorname',
      sorter: (a, b) => a.vorname.localeCompare(b.vorname),
      filters: _.uniqWith(filterData(benutzerliste)(i => i.vorname), _.isEqual),
      filteredValue: feldFilter.vorname || null,
      onFilter: (value, record) => record.vorname.includes(value),
    },
    {
      title: 'Nachname',
      dataIndex: 'nachname',
      key: 'nachname',
      sorter: (a, b) => a.nachname.localeCompare(b.nachname),
      filters: _.uniqWith(filterData(benutzerliste)(i => i.nachname), _.isEqual),
      filteredValue: feldFilter.nachname || null,
      onFilter: (value, record) => record.nachname.includes(value),
    },
    {
      title: 'Rollen',
      filters: benutzerliste
          .reduce(function(acc, b) {
            return acc.concat(b.rollen);
          }, [])
          .filter((value, index, array) => array.indexOf(value) === index)
          .map(s => ({ text: s, value: s})),
      filteredValue: feldFilter.rollen || null,
      onFilter: (value, record) => record.rollen.includes(value),
      dataIndex: 'rollen',
      key: 'rollen',
      render: (rs) => <>{rs.map(r => <Tag key={r}>{r}</Tag>)}</>
    },
    {
      title: 'Rang',
      dataIndex: 'rang',
      key: 'rang',
      filters: _.uniqWith(filterData(benutzerliste)(i => i.rang), _.isEqual),
      filteredValue: feldFilter.rang || null,
      onFilter: (value, record) => record.rang.includes(value),
      render: (rang) => <>{rang=='Kapitaen' ? "Kapitän" : rang}</>,
    },
    {
      title: 'Eintritt',
      dataIndex: 'eintrittsDatum',
      key: 'eintrittsDatum',
      render: (datum) => <>{datum ? dayjs(new Date(datum)).format('DD.MM.YYYY'): ""}</>,
    },
    {
      title: 'Austritt',
      dataIndex: 'austrittsDatum',
      key: 'austrittsDatum',
      render: (datum) => <>{datum ? dayjs(new Date(datum)).format('DD.MM.YYYY'): ""}</>,
    },
    {
      title: 'Tarifinfo',
      dataIndex: '',
      key: 'tarif',
    },
    {
      title: 'Aktionen',
      dataIndex: 'id',
      key: 'id',
      filteredValue: globalFilter ? [globalFilter] : null,
      onFilter: (value, record) =>
        record.nachname.toString().toLowerCase().includes(value.toLowerCase()) ||
        record.vorname.toString().toLowerCase().includes(value.toLowerCase())
      ,

      /*
      onFilter: (value, record) => {
        record.vorname.toString().toLowerCase().includes(value.toLowerCase()) ||
        record.nachname.toString().toLowerCase().includes(value.toLowerCase()) ||
        record.email.toString().toLowerCase().includes(value.toLowerCase())
      },
       */

      render: (id, record) => <Space>
        <Tooltip title="Benutzer bearbeiten">
          <Link to={"/settings/benutzer/details/" + id}><Button icon={<EditOutlined />} /></Link>
        </Tooltip>
        {record.rollen.includes('bordpersonal') ?
          <Tooltip title="SFN-Zuschläge bearbeiten"><Link to={"/bordpersonal/details/" + id}><Button icon={<SeamanIcon />} /></Link></Tooltip> :
          <Button icon={<SeamanIcon />} disabled={true} />
        }
        <Tooltip title="Benutzer löschen">
        <Popconfirm title="Benutzer löschen" description={<>Sollen die Benutzerdaten für<br/><i>{record.email}</i><br/>unwideruflich gelöscht werden?</>} onConfirm={() => deleteBenutzer(id)} okText="Ja" cancelText="Nein">
          <Button icon={<UserDeleteOutlined />} type="primary" danger />
        </Popconfirm>
        </Tooltip>

      </Space>,
    }
  ]

  return (
    <>
      <Layout>
        <Layout.Header style={{ backgroundColor: "#cccccc", display: 'flex'}} >
          <Link to={"/settings/benutzer/details/neu"}><Button icon={<UserAddOutlined/>} >Benutzer anlegen</Button></Link>
        </Layout.Header>
        <Content>

          {loading ? (
            <LadeIndikator />
          ) : (
            <Table
              title={() => (<TableTitle
                icon={<UserOutlined />}
                filtervalues={
                  benutzerliste
                    .reduce(function(acc, b) {
                      acc.push(b.vorname);
                      acc.push(b.nachname);
                      return acc;
                    }, [])
                    .filter((value, index, array) => array.indexOf(value) === index)
                    .map(s => ({ label: s, value: s}))
                }
                filter onFilter={setGlobalFilter} >Benutzer verwalten</TableTitle>)}

              columns={columns} dataSource={benutzerliste} onChange={handleTableChange}></Table>
          )}
        </Content>
      </Layout>
    </>
  )
}

export default Benutzerliste