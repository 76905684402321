import React, {useEffect, useState} from 'react'
import {
  Button,
  Layout,
  Table,
  message,
  Spin,
  DatePicker,
  Space,
  Popconfirm, Popover, Tooltip
} from 'antd';
import {Link, useNavigate, useParams} from "react-router-dom";
import {
  CheckOutlined, CommentOutlined,
  DeleteOutlined,
  EditOutlined,
  StopOutlined,
  UserAddOutlined,
  WarningTwoTone
} from "@ant-design/icons";
import _ from "lodash";
import toernService from "../../services/ToernService";
import authenticationService from "../../services/AuthenticationService";
import dayjs from "dayjs";
import TableTitle from "../base/TableTitle";
import {ToernIcon} from "../icons/CustomIcons";
import { Prompt } from "@five-show/antd-prompt";
import MonthSelector from "../base/MonthSelector";

const { Content } = Layout;

const ToernPruefenListe = () => {
  let {jahr, monat} = useParams();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const pMonat = jahr && monat ? dayjs(jahr + "-" + monat + "-01 00:00") : dayjs();

  const [datum, setDatum] = useState(pMonat);
  const [toernliste, setToernliste] = useState([]);
  const [loading, setloading] = useState(true);
  const [feldFilter, setFeldFilter] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");

  const error = (msg) => {
    messageApi.open({
      type: 'error', content: msg,
    });
  }

  useEffect(() => {
    loadZuPruefendeToerns()
  }, [datum]);

  const loadZuPruefendeToerns = async () => {
    setloading(true);
    await toernService.getZuPruefendeTorns().then(
      res => {
        setloading(false);
        setToernliste(
          res.data.map(row => ({
            key: `t_${row.id}`,
            id: row.id,
            startzeit: row.startzeit,
            endezeit: row.endezeit,
            schlepper: row.schlepper,
            rang: row.rang,
            benutzerrang: row.benutzerrang,
            ruhezeitenfehler: row.ruhezeitenfehler,
            ruhezeitenfehleranzahl: row.ruhezeitenfehleranzahl,
            ruhezeiten: row.ruhezeiten,
            benutzer: row.benutzer,
            hafentage: row.hafentage,
            reisekilometer: row.reisekilometer,
            shuttlekilometer: row.shuttlekilometer,
            kommentare: row.kommentare
//            stundenlohn: row.stundenlohn,
//            abschlag: row.abschlag
          }))
        )
      }
    ).catch((e) => {
      setloading(false);
      error(`Beim Laden der Törns ist ein Fehler aufgetreten:`);
      console.error(e);
    });
  };

  const handleAblehnen = (toernId, grund) => {
    var kmt = {
      text: grund,
    }
    toernService.toernAblehnen(toernId, kmt).then(
      res => {
        loadZuPruefendeToerns();
        messageApi.success('Der Törn wurde abgelehnt.');
      }
    ).catch((e) => {
      error(`Beim Ablehnen des Törns ist ein Fehler aufgetreten`);
    });
  }

  const toernGenehmigen = (toernId) => {
    toernService.toernGenehmigen(toernId).then(
      res => {
        loadZuPruefendeToerns();
        messageApi.success('Der Törn wurde genehmigt.');
      }
    ).catch((e) => {
      error(`Beim Genehmigen des Törns ist ein Fehler aufgetreten`);
    });
  }

  const promptAblehnen = async (toernId) => {
    try {
      await Prompt.prompt({
        label: "Bitte geben Sie einen Ablehnungsgrund ein:",
        required: true,
        onOk: (grund) => handleAblehnen(toernId, grund)
      });
    } catch (e) {
      console.log('prompt cancel', e);
    }
  }

  const handleChange = (pagination, filters, sorter) => {
    setFeldFilter(filters);
  };

  const changeMonat = (d) => {
    setDatum(d)
    navigate("/toerns/eingereicht/"  + d.format("YYYY/MM") , { replace: true})
  }
  const tabletitle = () => {
    return <TableTitle icon={<ToernIcon/>}
                  filtervalues={
                    toernliste
                      .map(t => t.schlepper)
                      .filter((value, index, array) => array.indexOf(value) === index)
                      .map(s => ({ label: s, value: s}))
                  }
                  onFilter={setGlobalFilter} >Zu prüfende Törns</TableTitle>
  }

  const filterData = toernliste => formatter => toernliste.map( item => ({
    text: formatter(item),
    value: formatter(item)
  }));

  const columns=[
    {
      title: 'Benutzer',
      dataIndex: 'benutzer',
      key: 'benutzer',
      sorter: (a, b) => a.benutzer.nachname.localeCompare(b.benutzer.nachname),
      //filters: _.uniqWith(filterData(toernliste)(i => i.schlepper), _.isEqual),
      //filteredValue: feldFilter.schlepper || null,
      //onFilter: (value, record) => record.schlepper.includes(value),
      render: (benutzer) => <>{benutzer.nachname} {benutzer.vorname}</>
    },
    {
      title: 'Schlepper',
      dataIndex: 'schlepper',
      key: 'schlepper',
      sorter: (a, b) => a.schlepper.localeCompare(b.schlepper),
      filters: _.uniqWith(filterData(toernliste)(i => i.schlepper), _.isEqual),
      filteredValue: feldFilter.schlepper || null,
      onFilter: (value, record) => record.schlepper.includes(value),
    },
    // TODO: Status
    // TODO: Manuelle Logeinträge?
    {
      title: 'Von',
      dataIndex: 'startzeit',
      key: 'startzeit',
      render: (datum) => <>{datum ? dayjs(new Date(datum)).format('DD.MM.YYYY HH:mm'): ""}</>,
      sorter: (a, b) => a.startzeit.localeCompare(b.startzeit),
    },
    {
      title: 'Bis',
      dataIndex: 'endezeit',
      key: 'endezeit',
      render: (datum) => <>{datum ? dayjs(new Date(datum)).format('DD.MM.YYYY HH:mm'): ""}</>,
      sorter: (a, b) => a.endezeit.localeCompare(b.endezeit),
    },
    {
      title: 'Rang',
      dataIndex: 'rang',
      key: 'rang',
      render: (rang, record) => <>{rang=='Kapitaen' ? "Kapitän" : rang} {(rang !== record.benutzerrang) ? (<Tooltip title="Rang weicht ab"><WarningTwoTone  title={"Benutzer: " + record.benutzerrang } /></Tooltip>) : "" }</>,
    },
    {
      title: 'Hafentage',
      dataIndex: 'hafentage',
      key: 'hafentage',
      render: (hafentage) => hafentage ? new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(hafentage) : "",
    },
    {
      title: 'Kilometer',
      dataIndex: 'reisekilometer',
      key: 'reisekilometer',
    },
    {
      title: 'Shuttle(km)',
      dataIndex: 'shuttlekilometer',
      key: 'shuttlekilometer',
    },
    {
      title: <Tooltip title="Anzahl Kommentare"><CommentOutlined /></Tooltip>,
      dataIndex: 'kommentare',
      key: 'kommentare',
      render: (k) => <>{k.length > 0 ? <Popover content={k[0].text} title={k[0].benutzer.vorname + " " + k[k.length - 1].benutzer.nachname}>{k.length}</Popover> : "-" }</>
    },
    {
      title: 'Aktionen',
      dataIndex: 'id',
      key: 'id',
      filteredValue: globalFilter ? [globalFilter] : null,
      onFilter: (value, record) => record.schlepper.toString().toLowerCase().includes(value.toLowerCase()),
      render: (id, record) => <Space>
        <Tooltip title="Törn genehmigen"><Button icon={< CheckOutlined />} type="primary" style={{backgroundColor:"#b7eb8f"}} success onClick={() => toernGenehmigen(id)} /></Tooltip>
        <Tooltip title="Törn ablehnen"><Button icon={< StopOutlined />} type="primary" onClick={() => promptAblehnen(id)} danger /></Tooltip>
        <Tooltip title="Törn bearbeiten"><Link to={"/toern/" + id}><Button icon={<EditOutlined />} /></Link></Tooltip>
      </Space>,
    }
  ]

  return (
    <>
      {contextHolder}
      <Layout>
        <Content>
          {loading ? (
            <div style={{ padding: '16px' }}>
              <Spin size="large" />
            </div>
          ) : (
            <Table title={tabletitle} columns={columns} dataSource={toernliste} onChange={handleChange}></Table>
          )}
        </Content>
      </Layout>
    </>
  )
}

export default ToernPruefenListe