import { Navigate } from 'react-router-dom';
import authenticationService from '../services/AuthenticationService'

const Private = ({
  redirectPath = '/login',
  children,
}) => {
  const auth = authenticationService.isUserLoggedIn();

  if (!auth) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default Private