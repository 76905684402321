import React, {useEffect, useState} from 'react'
import {Menu} from 'antd';
import {Link, Outlet, useLocation} from 'react-router-dom';
import authenticationService from "../../services/AuthenticationService";
import {CalendarOutlined, CheckCircleOutlined, ClockCircleOutlined, LineChartOutlined} from "@ant-design/icons";
import {MeinToernIcon, ToernIcon} from "../icons/CustomIcons";

const ToernSeitenMenu = () => {

  var menuitems = []
  if (authenticationService.isBordPersonal()) {
    menuitems.push({
        label: (
          <Link to="/toerns/aktive">Aktuelle Törns</Link>
        ),
        key: 'meine',
        icon: <MeinToernIcon />,
      }
    )
    menuitems.push({
        label: (
          <Link to="/toerns/meine">Meine Törns</Link>
        ),
        key: 'zeit',
        icon: <ToernIcon/>,
      }
    )
  }
  if (authenticationService.hatEineRolle(['heuer', 'admin'])) {
    menuitems.push({
        label: (
          <Link to="/toerns/eingereicht">Törns zur Prüfung</Link>
        ),
        key: 'pruefen',
        icon: <CheckCircleOutlined/>,
      }
    )
    menuitems.push({
        label: (
          <Link to="/toerns/alle">Alle Törns</Link>
        ),
        key: 'alle',
        icon: <ClockCircleOutlined/>,
      }
    )
    menuitems.push({
        label: 'Berichte',
        key: 'berichte',
        icon: <LineChartOutlined />,
        children: [
          { label: <Link to="/toerns/berichte/monat">Monatsberichte</Link>, key: 'berichte:monat' },
          { label: <Link to="/toerns/berichte/genehmigung">Genehmigungszeitraum</Link>, key: 'berichte:genehmigung' },
        ]
      }
    )
  }

  const location = useLocation();

  const tabAusPfad = () => {
    if (location.pathname.includes("meine")) {
      return "zeit"
    } else if (location.pathname.includes("eingereicht")) {
      return "pruefen"
    } else if (location.pathname.includes("alle")) {
      return "alle"
    } else if (location.pathname.includes("/berichte/")) {
      return "berichte"
    }
    return "meine"
  }

  const [current, setCurrent] = useState(tabAusPfad());

  useEffect(() => {
    setCurrent(tabAusPfad());
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMenuItemSelected = (e) => {
    setCurrent(e.key);
  };

  return (
    <>
      <Menu onClick={onMenuItemSelected} selectedKeys={[current]} mode={"horizontal"} items={menuitems}>
      </Menu>
      <Outlet />
    </>
  )
}
export default ToernSeitenMenu

