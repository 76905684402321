import React from 'react'
import authenticationService from '../../services/AuthenticationService';
import {Alert, Avatar, Button, Card, Divider, Form, Input, Layout, message, Space} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';

const {Content} = Layout;

const PasswortVergessenSeite = () => {

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fehler, setFehler] = React.useState(null);

  const error = (msg) => {
    messageApi.open(    {
      type: 'error', content: msg,
    });
  }

  const doSendToken = (values) => {
    setFehler(null);
    authenticationService.createPasswortVergessenToken(values.email).then(() => {
      navigate('/passwort-token?email=' + values.email);
    }, () => {
      setFehler( "Der Passwortdienst steh momentan nicht zur Verfügung. Bitte Laden Sie die Seite neu!");
      error( fehler);
    } ).catch((e) => {
      if (e.response && e.response.data) {
        setFehler("Es ist ein Fehler aufgetreten: " + e.response.data.message);
     } else if (e.request) {
        setFehler("Der Server antwortet im Moment nicht. Bitte versuchen Sie es in Kürze erneut")
      } else {
        setFehler( "Es ist ein unerwartetes Problem bei der Anmeldung aufgetreten!");
      }
      error( fehler);
    })
  }

  return (<>
    {contextHolder}
    <Layout>
        <Content className='login-content'>
          <Card cover={<img alt="Boluda" src="/img/boluda-header_web.jpeg"/>}>
            <Card.Meta
              avatar={<Avatar style={{backgroundColor: '#152947'}} icon={<UserOutlined/>}/>}
              title="Passwort vergessen"
              description={<>Um ein neues Passwort zu vergeben, geben Sie bitte die Emailadresse ein mit der Sie am System registriert sind.<br/>An diese Emailadresse wird ein Code zur Wiederherstellung Ihres Passworts versendet.</>}
            />
            <Divider/>

            <Space>
              <Form form={form} className='login-form' onFinish={doSendToken}>

                {fehler &&
                  <Form.Item><Alert message={fehler} showIcon type="error"/></Form.Item>}

                <Form.Item name="email"
                           rules={[{type: 'string', min: 6}, {type: 'email', warningOnly: true}, {
                             required: true, message: 'Bitte geben Sie Ihre Emailadresse ein!',
                           },]}

                >
                  <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Emailadresse"/>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Wiederherstellungscode zusenden
                  </Button>
                </Form.Item>
              </Form>
            </Space>
          </Card>
        </Content>
      </Layout>
    </>)
}

export default PasswortVergessenSeite

