import React, {useEffect} from 'react'
import authenticationService from '../../services/AuthenticationService';
import {Result, Spin} from 'antd';
import {HourglassOutlined} from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom';

const LogoutSeite = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    doLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doLogout = () => {
    authenticationService.doLogout();
    if (props.onLogout) {
      props.onLogout(authenticationService.isUserLoggedIn)
    }
    navigate('/login',{});
  }

  return (
    <Result
      icon={<HourglassOutlined/>}
      title="Sie werden ausgeloggt!"
      extra={<><Spin size="small"/><Link to="/login">Zum Login</Link></>}
    />
  )

}

export default LogoutSeite